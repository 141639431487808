body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;

  /* background-image: url("src/assets/images/bg.png");
  background-color: rgba(0, 0, 0, 0.8); */

  background : radial-gradient(circle, transparent 10%, black 60%) , url("src/assets/images/bg.png");

  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  /* background-blend-mode: multiply; */
  /* mix-blend-mode: overlay; */
  /* z-index: -1; */
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position-x: right;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
