* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
}
body {
  overflow-x: hidden;
}

.App {
  width: 100%;
}

b {
  font-weight: 600;
  color: inherit;
}
html {
  scroll-behavior: smooth;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centerColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.flexJustify {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.mainContainer {
  position: relative;
  background: transparent;
  /* z-index: -1; */
}

.mainContainer-anim::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  box-shadow: 0 0 17px 3px #fff, 0 0 4px 2px #fff;
  z-index: -1;
  -webkit-animation-name: yellow-shadow;
  animation-name: yellow-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.mainContainer-anim::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  -webkit-box-shadow: 0 0 17px 3px #7c69e3, 0 0 4px 2px #7c69e3;
  box-shadow: 0 0 17px 3px #7c69e3, 0 0 4px 2px #7c69e3;
  z-index: -1;
  -webkit-animation-name: cyan-shadow;
  animation-name: cyan-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@-webkit-keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 50%;
    left: 0;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  75% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 50%;
    left: 0;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  75% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@-webkit-keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 0;
    bottom: 50%;
  }
  50% {
    right: 50%;
    bottom: 50%;
  }
  75% {
    right: 50%;
    bottom: 0;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}

@keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 0;
    bottom: 50%;
  }
  50% {
    right: 50%;
    bottom: 50%;
  }
  75% {
    right: 50%;
    bottom: 0;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}
